h3.role {
    margin-left: 0;
}

.items.style1#roles {
    padding-right: 2.1875rem;
    padding-left: 2.1875rem;
}

ul.actions#apply_buttons {
    justify-content: center;
}