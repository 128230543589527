h3#calendar_title {
    margin: 1rem 0 0 1rem;
    overflow: hidden;
}

.rbc-toolbar .rbc-toolbar-label {
    padding: 0;    
    margin-top: 15px;
}

.button.small#subscribe {
    float: right;
    margin-right: 1rem;
}